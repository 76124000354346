import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function CFO() {
  /* Slider */
  // const CFOSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 60,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Virtual CFO Services`,
  //     content: `TODAYFILINGS, India's largest virtual CFO services service, can assist you with your business`,
  //     image: "/imgs/business/responsible.png",
  //     alt_tag: "Best Online Virtual CFO Services in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Virtual CFO Service `,
    buyBtnLink: `#pricing-buy`,
    price: `8999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data Without Form*/
  const CFOAboutData = {
    title: `Virtual CFO Services`,
    sub_title: `Largest Virtual CFO Services in India`,
    paragraph: `Your company's accounting and transaction recording chores are conveniently managed
        through Virtual CFO Services. These virtual CFO advising and consulting services in
        India are affordable, and they can be expanded to meet your business's changing demands.Your company's accounting 
        can be handled by our experts and specialists to guarantee
        that your company is always tax-compliant without any internal disagreements or controversies.`,
    header: `Private businesses now have a path that will allow them to
    access a skilled financial professional at a fair price thanks to Virtual CFO.
    Despite the fact that each company's CFO services are unique.`,
    points: [],
  };

  /* Img Content Component Data */
  const CFOIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Change the work style',
    image: '/imgs/registration/dsc/dsc-doc.png',
    alt_tag: "Secretarial Audit's Applicability in India",
    Paragraph: '',
    points: [
      {
        content: `You must change how you connect with your clients and establish a
        cloud-based accounting system before you can install a virtual CFO service in 
        your company.Stop focusing your business on process-driven administrative procedures.`,
        icon: true,
      },
      {
        content: `Maintain a professional dialogue with your clients. Provide more consulting-related 
        help Try to establish yourself as a trusted business advisor.`,
        icon: true,
      },
      {
        content: `Stop focusing your business on process-driven services.`,
        icon: true,
      },
      {
        content: `Providing more consultation services.`,
        icon: true,
      },
      {
        content: `Assist clients in attaining their objectives.`,
        icon: true,
      },
      {
        content: `Maintain solid business relationships with your clientele.`,
        icon: true,
      },
      {
        content: `Provide your clients with input on the expansion of their business.`,
        icon: true,
      },
      {
        content: `Become a respected business advisor by all means.`,
        icon: true,
      },
    ],
  };
  /* Document Section Data */
  const CFODocData = {
    id: 'cfo-doc',
    heading: `Advantages of Virtual CFO Services`,
    sub_heading: `There is some essential benefits of CFO service,`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/budgeting.png`,
        alt_tag: `Plan for growth and budgeting`,
        header: `Plan for growth and budgeting`,
        description: `By combining the cost and the pay, planning aids in determining the amount of
                money needed. The management of the association's finances by a virtual CFO
                aids in the expansion of the business.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/organization.png`,
        alt_tag: `Hierarchical Organization`,
        header: `Hierarchical Organization`,
        description: `Virtual CFO administrations have the capacity to implement any changes to
                the organizational hierarchy as and when they become necessary for the
                growth of the company.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/eco-projections.png`,
        alt_tag: `Economic projections`,
        header: `Economic projections`,
        description: `The accent is on financial projection rather than estimating costs and
                salaries. It includes market factor data in its composition. It aids in
                verifying the need for resources and money.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/acc-records.png`,
        alt_tag: `Assists in keeping accounting records`,
        header: `Assists in accounting records`,
        description: `We are undoubtedly all aware, the financial statements of any company are
                prepared in accordance with the norms of the bookkeeping regulations as set
                forth by the relevant governmental body.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/it/analyzing-fin.png`,
        alt_tag: `Analyzing finances`,
        header: `Analyzing finances`,
        description: `The association's dynamic cycle, which ultimately encourages corporate growth.
                By using distinct proof of important activity markers, it analyses the organization's
                finances and operations.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/it/profit-increase.png`,
        alt_tag: `Increasing profitability`,
        header: `Increasing profitability`,
        description: `Your possessions are safeguarded from such a loss by the administrations of
                a virtual CFO. The total effectiveness of the business is increased by a
                well-planned and implemented system.`,
      },
    ],
  };
  /* FaQ Data */
  var CFOFAQ = [
    {
      header: 'What services does Virtual CFO provide?',
      body: [
        {
          content: `An experienced management company called Virtual CFO offers HR,
                    enrolling, and accounting services.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header: 'How many cycles will there be?',
      body: [
        {
          content: `The process will last until long is necessary; it entirely depends
                    on the demands of the client.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What should I look for in a virtual CFO?',
      body: [
        {
          content: `A true VCFO should be able to provide a higher level of value than a traditional accountant or 
          bookkeeper. They should essentially become a member of your team and help you with higher-level financial 
          strategy, forecasting, and cash flow management.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Why do I need a Virtual CFO?',
      body: [
        {
          content: `If you work for a small business, you may only require simpler financial services provided by a 
          bookkeeper or accountant. Chances are, if you're reading this FAQ page, you've likely worked with or are 
          currently working with a bookkeeper or accountant.`,
          icon: true,
        },
        {
          content: `As your business grows, or if you're planning for growth, your financial needs become more complex.
           Often your existing financial infrastructure and accounting reporting aren't giving you the kind of visibility 
           you need to make confident, financially sound decisions around growth.`,
          icon: true,
        },
      ],
    },
    {
      header: `Why would it be a good idea for me to use the services of a Virtual
            CFO?`,
      body: [
        {
          content: `Re-appropriating CFO funds for business-related causes is mostly
                    done to advance the enterprise. It facilitates the business
                    leaders' ability to concentrate on the numerous business
                    activities.`,
          icon: false,
        },
      ],
    },
    {
      header: `Why should I use TODAYFILINGS Virtual CFO Services?`,
      body: [
        {
          content: `Why not? Our team has the experienced “credentials” some companies demand. Due to our being a 
          distributed company we have the luxury  of the crop, with no geographic limitations. 
          Additionally, our team has a profound knowledge of “process” they know how to tackle tasks, get them 
          done, and meet deadlines.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is my business big enough for a virtual CFO?`,
      body: [
        {
          content: `If you need to expand your company and streamline the finest
                    strategy for appearing, you require a virtual CFO that can help
                    you with the crucial organizing step and recognize the
                    significant, clear goals that need to be achieved.`,
          icon: false,
        },
      ],
    },
  ];
  /* Tabs Data */
  const CFOTabsData = {
    id: 'features',
    heading: 'Importance Services of Virtual CFO',
    paragraph: '',
    body: [
      {
        tab_title: 'Finance',
        tab_content: [
          {
            content_title: `Budgeting`,
            content_paragraph: `Planning involves virtual CFO companies. The purpose of
                    finishing the planning process is to keep track of all the
                    tasks that the company is carrying out. Spending should be
                    examined on a monthly or quarterly basis so that
                    adjustments can be made to achieve the main goal.`,
            points: [],
          },
          {
            content_title: 'Finance Planing',
            content_paragraph: `Virtual CFO arranges actual obligations with the intention
                        of achieving the desired outcome. To manage the
                        commitment, a proper obligation arrangement is necessary.`,
            points: [],
          },
          {
            content_title: 'Analyzing Finances',
            content_paragraph: `Conjecturing incomes is the virtual CFO's other key
                        support. In order to make the best choice, associations
                        need a deeper understanding of their financial positions.
                        You most likely created enough financial preparations to
                        handle the upcoming obligations. It aids in
                        decision-making on the quantity of assets needed.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/cfo-finance.png',
        alt_img: 'TODAYFILINGS CFO Finance Planing',
      },
      {
        tab_title: 'Accounting',
        tab_content: [
          {
            content_title: 'Accounting Guidelines And Techniques',
            content_paragraph: `Techniques and techniques for bookkeeping aid in the
                    organization and application of extremely distinctive
                    bookkeeping by association executives. An specifically
                    organized and properly maintained arrangement of
                    bookkeeping techniques aids in the overhaul of
                    responsibility and consistency for an expanded structure
                    of inside control in the association.`,
            points: [],
          },
          {
            content_title: 'Closure and Filing of Year End Accounts',
            content_paragraph: `The timely development and annual documentation of budget
                    summary is ensured by Virtual CFO. Additionally, it makes
                    sure that GST documentation and Income Tax return
                    archiving are done.`,
            points: [],
          },
          {
            content_title: 'Accounting duties of the business',
            content_paragraph: `A complete evaluation of the budgetary and bookkeeping
                    parts of the job is implied by an accounting health check.
                    A prominent master inside the firm is required to put up
                    extensive data pertaining to the accounting practices,
                    such as data organizing and other bookkeeping
                    arrangements.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/customized-consulting.png',
        alt_img: 'TODAYFILINGS Virtual CFO Accounting Compliance',
      },
      {
        tab_title: 'Management',
        tab_content: [
          {
            content_title: 'Internal Regulation',
            content_paragraph: `A real influence on the development of associations by
                    means of a streamlined internal control structure aids in
                    maintaining the affiliation's unmovable nature. The
                    association's CFO aids in the evaluation and
                    implementation of these recently mentioned structures.`,
            points: [],
          },
          {
            content_title: 'Income Prediction',
            content_paragraph: `In order to make the best choice, associations need a
                    deeper understanding of their financial positions. It aids
                    in decision-making regarding the necessary amount of
                    assets and liabilities.`,
            points: [],
          },
          {
            content_title: 'Cost Controlling',
            content_paragraph: `A virtual CFO creates a real need to limit the
                    association's costs using the cost the board methodology.
                    The choice of the working profitability is assisted by a
                    virtual CFO. The virtual CFO separates a variable expense
                    in order to manage it.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/cfo-management.png',
        alt_img: 'TODAYFILINGS Virtual CFO Management Compliance',
      },
      {
        tab_title: 'Auditing',
        tab_content: [
          {
            content_title: 'Corporate Responsibility',
            content_paragraph: `All of the norms and policies that a corporation adheres
                    to are included in corporate administration. These
                    principles aid in balancing the excitement of business
                    partners, investors, executives, and clients.`,
            points: [],
          },
          {
            content_title: `Reporting for MIS`,
            content_paragraph: `The virtual CFO is responsible for providing clear and
                    accurate information about the association's financial
                    stability via MIS reports.`,
            points: [],
          },
          {
            content_title: `Audit Assistance`,
            content_paragraph: `The virtual CFO provides a comprehensive review by
                    answering the inspectors' queries.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/cfo-auditing.png',
        alt_img: 'TODAYFILINGS Virtual CFO Auditing Compliance',
      },
    ],
  };
  return (
    <div>
      <Seo
        title='Virtual CFO Services'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={CFOSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={CFOAboutData} />

          <Counter />

          <ImgContent item={CFOIcData} />

          <Document docSecData={CFODocData} />
          <GeneralTab HTabsData={CFOTabsData} />
          <Cta />
          <FAQAccord items={CFOFAQ} />
        </main>
      </Layout>
    </div>
  );
}
